import React from 'react';
import { PageProps } from "gatsby";
import Layout from '../components/layout/layout';
import './index.scss';

const previewImage = {imageFile: 'home.png', imageWidth: 1200, imageHeight: 630, imageDescription: 'Screenshot of the website'};

export default function PageContent(props: PageProps) {
  let language = 'en';
  return (
    <Layout language={language} title="Welcome" description="The start page" path={props.path} previewimage={previewImage} hasLanguageCounterpart={true}>
      <div className="pageHeroContainer">
        <img className="pageHeroImage" src="/images/code_on_screen.jpg" alt="Some Angular Code" />
      </div>
      <h1>Programming, web, multimedia.</h1>
      <h3>Three keywords that describe my interests, what I love to do since roughly 1995.</h3>
      <p>
        I grew up with pysical photos and slides, VHS and Hi8 video cameras as well as Commodore 64 and Amiga 500 / 2000 computers.
        In my teenage years I got into IBM compatible computers and during my apprenticeship as industrial electronics specialist (specializing in device technology) I got in touch with PLC’s and Microcontrollers as well.
      </p>
      <p>
        With the advancing technology and upcoming world wide web I played around with more and more things, starting to make software development my profession around 2005 - while returing a bit to electronics with the rise of the Internet of Things.
      </p>
      <p>
        Beside software development my free time hobbies revolves around digital media like photography, video and animation. So if you want to know more or just see what I have done and am still doing have a look around. Enjoy your time here...
      </p>
    </Layout>
  )
}
